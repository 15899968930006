import { Suspense, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import PageLoader from '../components/shared/page-loader/PageLoader';
import { layoutMarginsAtom } from '../recoil/atoms/Layout';
import { FCWithChildren } from '../types/FCWithChildren';

const MinimalLayout: FCWithChildren<unknown> = (props) => {
  const { children } = props;
  const setLayoutMargins = useSetRecoilState(layoutMarginsAtom);

  useEffect(() => {
    setLayoutMargins('');

    return () => setLayoutMargins('');
  }, [setLayoutMargins]);

  return (
    <div className="h-full w-full">
      <Suspense fallback={<PageLoader loading isSuspense />}>{children}</Suspense>
    </div>
  );
};

export default MinimalLayout;
